import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import CatalogPartnersTableDetails from "./CatalogPartnersTableDetails";
import AddAttributes from "../Partners-Inventories/AddAttributes";
import {
    getPartnerAttributes,
    getPartnerById,
} from "../../redux/actions/PartnerActions";
import { useDispatch, useSelector } from "react-redux";
import MappingDestinationModal from "./MappingDestinationModal";
import {
    AFFILIATE,
    GIATA_MODEL,
    HOME,
    MANAGE_PARTNER_ATTRIBUTES,
    MAP_MANUALLY,
    PARTNERS,
} from "../../utilis/const";
import { getOutdatedCatalogs } from "../../redux/actions/PartnerCataogActions";
import PartnerCatalogService from "../../services/PartnerCatalogService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { hasPermission } from "../../utilis/functions";
import {
    STATUS_SUCCESS_INVENTORY,
    STATUS_SUCCESS_CATALOG,
    STATUS_PROCESSING,
    MAP,
} from "../../utilis/const";
import PartnerReportService from "../../services/PartnerReportService";
import PartnerHeaderDetails from "./PartnerHeaderDetails";
import AffiliateHeaderDetails from "./AffiliateHeaderDetails";
import ExternalShare from "../../services/ExternaleShare"
import moment from "moment";

function PartnerCatalogDetails() {
    const history = useHistory();
    const params = useLocation();
    const updatedQuery = new URLSearchParams(params.search);
    const dispatch = useDispatch();
    const { id, affiliate_id } = useParams();
    const [pendingMappingReport, setPendingMappingReport] = useState(false);
    const [showAddAttributes, setShowAddAttributes] = useState(false);
    const [loadingRunMapping, setLoadingRunMapping] = useState(false);
    const [openMappingDestinationDialog, setOpenMappingDestinationDialog] =
        useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [currentElement, setCurrentElement] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [openExternalShare,setopenExternalShare] = useState(false);
    const [loadingExternalShare,setloadingExternalShare] = useState(false);
    const [shareExternaleData,setshareExternaleData] = useState({}); 
    const pageType = params?.state?.params?.pageType;

    const {
        activeCatalog,
        outadatedCatalog,
        catalogs,
        partnerGroupedCatalogs,
        loadingPartnerGroupedCatalog,
        partner,
        loadingPartner,
    } = useSelector((state) => ({
        partner:
            {
                ...state.partnerReducer.partner,
                affiliate_id: affiliate_id ?? 0,
            } ?? {},
        loadingPartner: state.partnerReducer.loadingPartner,
        activeCatalog: state?.partnerReducer.partnerGroupedCatalogs?.header,
        catalogs: state.catalogReducer?.catalogs ?? [],
        outadatedCatalog: state.partnerCatalogReducer.outdatedCatalogs?.find(
            (e) => e.partner_id === id
        ),
        partnerGroupedCatalogs:
            state?.partnerReducer.partnerGroupedCatalogs?.data ?? [],
        loadingPartnerGroupedCatalog:
            state?.partnerReducer.loadingPartnerGroupedCatalog,
    }));

    useEffect(() => {
        dispatch(getOutdatedCatalogs());
        dispatch(getPartnerById(id));
    }, []);
    const calculHeaderPourcentage = () => {
        let calculElement = {};
        if (activeCatalog === undefined) {
            calculElement = {
                mapped_hotels: 0,
                total_hotels: 0,
                unmapped_hotels: 0,
                lastMappedPourcentage: 0,
                mappedPourcentage: 0,
                unmappedPourcentage: 0,
            };
        } else {
            let mapped_hotels = activeCatalog?.mapped_hotels ?? 0;
            let unmapped_hotels = activeCatalog?.unmapped_hotels ?? 0;
            let valid_hotels =
                activeCatalog?.model === GIATA_MODEL
                    ? activeCatalog?.total_hotels ?? 0
                    : activeCatalog?.valid_hotels ?? 0;
            let lastMappedPourcentage =
                valid_hotels != 0 ? (mapped_hotels / valid_hotels) * 100 : 0;
            let mappedPourcentage =
                valid_hotels != 0 ? (mapped_hotels / valid_hotels) * 100 : 0;
            let unmappedPourcentage =
                valid_hotels != 0 ? (unmapped_hotels / valid_hotels) * 100 : 0;
            calculElement = {
                ...activeCatalog,
                lastMappedPourcentage: lastMappedPourcentage,
                mappedPourcentage: mappedPourcentage,
                unmappedPourcentage: unmappedPourcentage,
            };
        }
        return calculElement;
    };

    const GetMappingExternalInfo = async() =>{
            setloadingExternalShare(true)
            const _ExternalShare = await ExternalShare.getShareExternal(partner.id)
            if(_ExternalShare){
                setloadingExternalShare(false)
                if(_ExternalShare.status == 200){
                    console.log("_ExternalShare => " , _ExternalShare.data)
                    const newData ={... _ExternalShare.data}
                    newData.manual_mapping_token.token = `${window.location.origin}/get-started?token_external_manual_mapping=${_ExternalShare.data.manual_mapping_token.token}&token_mapping_report=${_ExternalShare.data.report_token}`                    
                    setshareExternaleData(newData.manual_mapping_token)
                    setopenExternalShare(true)
                }
                else
                    toast.error(_ExternalShare.message,{toastId:_ExternalShare.message})
            }

    }
    useEffect(()=>{
            const _getActivePartner =partnerGroupedCatalogs.find(item=>item.parent.active)
            if(_getActivePartner){
                if( 
                    hasPermission( MAP_MANUALLY) 
                    && _getActivePartner 
                    && _getActivePartner?.parent?.unmapped_hotels > 0 
                    && _getActivePartner?.parent?.active === true 
                    && _getActivePartner?.parent?.affiliate_id === 0 
                    && _getActivePartner?.parent?.model !== GIATA_MODEL
                ){
                    updatedQuery.set('isExternalShare', true);
                    history.replace({
                        pathname: params.pathname,
                        search: updatedQuery.toString(),
                    });
                    return true
                }
                return null
            }

            return  null
        },[partnerGroupedCatalogs])

    return (
        <>
            <Helmet>
                <title>Partners content</title>
            </Helmet>

            <div className="page-wrapper" style={{ display: "block" }}>
                {loadingPartner ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="page-breadcrumb">
                            <div className="row">
                                <div className="col-12 align-self-center">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    if (
                                                        pageType === AFFILIATE
                                                    ) {
                                                        history.push(
                                                            "/affiliate"
                                                        );
                                                    } else if (
                                                        pageType === HOME
                                                    ) {
                                                        history.push("/home");
                                                    } else if (
                                                        pageType === PARTNERS
                                                    ) {
                                                        history.push(
                                                            "/partners"
                                                        );
                                                    } else {
                                                        history.push(
                                                            "/provider-content"
                                                        );
                                                    }
                                                }}
                                                style={{ paddingLeft: "0px" }}
                                            >
                                                <i className="fas fa-arrow-left"></i>
                                            </button>
                                            {`${partner?.name ?? ""} details`}
                                        </h3>
                                    </div>
                                </div>
                                {activeCatalog?.has_mapping_error && (
                                    <div className="col-lg-12 col-md-12">
                                        <div
                                            className="alert alert-warning"
                                            role="alert"
                                            style={{
                                                borderRadius: "12px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div style={{ marginTop: "9px" }}>
                                                <i
                                                    className="fas fa-exclamation-triangle"
                                                    style={{
                                                        marginRight: "6px",
                                                    }}
                                                ></i>
                                                A mapping error was removed from
                                                this partner's active catalog
                                                mapping. Please make sure you
                                                download or export the updated
                                                version.
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {outadatedCatalog && (
                                    <div className="col-lg-12 col-md-12">
                                        <div
                                            className="alert alert-warning"
                                            role="alert"
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                        >
                                            <i
                                                className="fas fa-exclamation-triangle"
                                                style={{
                                                    marginRight: "6px",
                                                }}
                                            ></i>
                                            {catalogs?.find(
                                                (e) =>
                                                    e?.mapping_process_status_id ==
                                                        STATUS_SUCCESS_CATALOG &&
                                                    e?.active == true
                                            ) && hasPermission(MAP)
                                                ? `Your inventory has been updated, re-run this mapping ?`
                                                : !hasPermission(MAP)
                                                ? `Your inventory has been updated`
                                                : `Your inventory is still processing`}

                                            {catalogs?.find(
                                                (e) =>
                                                    e?.mapping_process_status_id ==
                                                        STATUS_SUCCESS_CATALOG &&
                                                    e?.active == true
                                            ) &&
                                                hasPermission(MAP) && (
                                                    <button
                                                        className="btn text-link-button2"
                                                        style={{
                                                            marginBottom: "4px",
                                                            marginLeft: "4px",
                                                        }}
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            setLoadingRunMapping(
                                                                true
                                                            );
                                                            try {
                                                                const res =
                                                                    await PartnerCatalogService.runOutdatedMapping(
                                                                        [
                                                                            partner?.partner_id,
                                                                        ]
                                                                    );
                                                                setLoadingRunMapping(
                                                                    false
                                                                );

                                                                if (
                                                                    res.status ===
                                                                    200
                                                                ) {
                                                                    toast.success(
                                                                        "Success"
                                                                    );
                                                                } else {
                                                                    toast.error(
                                                                        res.message
                                                                    );
                                                                }
                                                            } catch {
                                                                setLoadingRunMapping(
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                        disabled={
                                                            loadingRunMapping
                                                        }
                                                    >
                                                        {loadingRunMapping && (
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        Run Mapping
                                                    </button>
                                                )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                {(catalogs?.find(
                                    (e) =>
                                        e?.mapping_process_status_id ===
                                            STATUS_PROCESSING &&
                                        e?.active === true
                                ) &&
                                    partnerGroupedCatalogs?.length === 0) ||
                                (!catalogs?.find(
                                    (e) =>
                                        e?.mapping_process_status_id ==
                                            STATUS_SUCCESS_INVENTORY &&
                                        e?.active == true
                                ) &&
                                    partnerGroupedCatalogs?.length === 0) ||
                                (partnerGroupedCatalogs?.length === 0 &&
                                    !loadingPartnerGroupedCatalog) ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="col-12 col-md-12 col-sm-12">
                                            <div
                                                className="api-key-bloc"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <div className=" flex-row justify-content-end  gap-3">
                                                    {
                                                        updatedQuery.get('isExternalShare')  
                                                        &&
                                                        (
                                                        <button
                                                            disabled={loadingExternalShare}
                                                            style={{ width: "fit-content", }}
                                                            className="btn btn-info"
                                                            onClick={() => {GetMappingExternalInfo()}}
                                                        >
                                                            <i
                                                                className="fas fa-share-alt"
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                }}
                                                            ></i>
                                                            External sharing 
                                                             {loadingExternalShare && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </button>
                                                    )}
                                                    {hasPermission(
                                                        MANAGE_PARTNER_ATTRIBUTES
                                                    ) && (
                                                            <ButtonAddAttribute
                                                                setShowAddAttributes={
                                                                    setShowAddAttributes
                                                                }
                                                                item={partner}
                                                            />
                                                    )}  
                                                    <button
                                                            className="btn btn-primary"
                                                            style={{
                                                                borderRadius:
                                                                    "9px",
                                                                width: "fit-content",
                                                            }}
                                                            disabled={
                                                                pendingMappingReport
                                                            }
                                                            onClick={async () => {
                                                                setPendingMappingReport(
                                                                    true
                                                                );
                                                                try {
                                                                    const res =
                                                                        await PartnerReportService.getReportToken(
                                                                            activeCatalog?.id
                                                                        );

                                                                    if (
                                                                        res.status ===
                                                                        200
                                                                    ) {
                                                                        window.open(
                                                                            `${window.location.origin}/partner-reports?token=${res?.data?.token}`
                                                                        );
                                                                        setPendingMappingReport(
                                                                            false
                                                                        );
                                                                    } else {
                                                                        toast.error(
                                                                            res?.message ??
                                                                                "An error has occured"
                                                                        );
                                                                        setPendingMappingReport(
                                                                            false
                                                                        );
                                                                    }
                                                                } catch (e) {
                                                                    toast.error(
                                                                        "An error has occured"
                                                                    );
                                                                    setPendingMappingReport(
                                                                        false
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <i
                                                                className="fas fa-share-alt"
                                                                style={{
                                                                    marginRight:
                                                                        "4px",
                                                                }}
                                                            ></i>
                                                            Mapping report
                                                            {pendingMappingReport && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                    </button>
                                                </div>
      
                                            </div>
                                        </div>
                                        {partner?.affiliate_id === 0 && (
                                            <PartnerHeaderDetails
                                                activeCatalog={activeCatalog}
                                                calculHeaderPourcentage={
                                                    calculHeaderPourcentage
                                                }
                                            />
                                        )}
                                        {partner?.affiliate_id > 0 && (
                                            <AffiliateHeaderDetails
                                                activeCatalog={activeCatalog}
                                                partner={partner}
                                            />
                                        )}
                                    </>
                                )}
                                <div className="col-12 col-md-12 col-lg-12">
                                    <CatalogPartnersTableDetails
                                        partner={partner}
                                        showTooltip={showTooltip}
                                        setShowTooltip={setShowTooltip}
                                        openDialog={openDialog}
                                        setOpenDialog={setOpenDialog}
                                        pageType={pageType}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {showAddAttributes && (
                <AddAttributes
                    show={showAddAttributes}
                    handleClose={() => setShowAddAttributes(false)}
                    currentElement={partner}
                />
            )}
            {openMappingDestinationDialog && (
                <MappingDestinationModal
                    show={openMappingDestinationDialog}
                    dataCell={currentElement}
                    addOriginPath={true}
                    handleClose={() => {
                        setOpenMappingDestinationDialog(false);
                    }}
                />
            )}
            {openExternalShare ? <ExtirnalSharingMapping loadingExternalShare={loadingExternalShare} GetMappingExternalInfo={GetMappingExternalInfo} setshareExternaleData={setshareExternaleData} shareExternaleData={shareExternaleData} openExternalShare={openExternalShare} setopenExternalShare={setopenExternalShare}/> : null}
        </>
    );
}
export default PartnerCatalogDetails;

function ButtonAddAttribute(props) {
    const dispatch = useDispatch();
    const [pending, setPending] = React.useState(false);
    const { item, setShowAddAttributes } = props;

    return (
        <button
            disabled={pending}
            onClick={async () => {
                setPending(true);
                await dispatch(getPartnerAttributes(item?.id));
                //setCurrentElement(item);
                setPending(false);
                setShowAddAttributes(true);
            }}
            style={{ width: "fit-content" ,margin:"0px 10px"}}
            className="btn btn-info"
        >
            <i className="fas fa-cogs" style={{ marginRight: "10px" }}></i>
            Manage partner attributes 
            {pending && (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            )}
        </button>
    );
}


const ExtirnalSharingMapping = ({setopenExternalShare,shareExternaleData,setshareExternaleData,GetMappingExternalInfo,loadingExternalShare}) =>{
    const [switchBtn,setSswitchBtn] = useState(shareExternaleData.active)
    const [Loading,setSLoading] = useState(false)
    const [emails,setEmails] = useState('')
    const [isCopier,setisCopier] = useState(false)
    const sendFunction = async(isActive=switchBtn) =>{
        setSLoading(true)
        const getEmails = emails.replace(/\s/g, "").split(",")
        const generateData = {
            id: shareExternaleData.id,
            active: isActive,
            emails_array: getEmails.length == 1 && getEmails[0]=="" ? [] : getEmails,
            expiration_date: shareExternaleData.expiration_date.includes('T') ? shareExternaleData.expiration_date.split('T')[0] : shareExternaleData.expiration_date
        }
        const _PostShareExternal = await ExternalShare.PostShareExternal(generateData)
        if(_PostShareExternal){
                setSLoading(false)
                if(_PostShareExternal.status == 200){
                    toast.success("Data updated successfully",{toastId:"Data updated successfully"})
                }
                else
                    toast.error(_PostShareExternal.message,{toastId:_PostShareExternal.message})
            }
        
        }
    const copierUrl = ()=>{
        const copyText = document.getElementById("URLInput")
        var range = document.createRange();
        range.selectNode(copyText);
        var selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
        document.execCommand("copy");
        setisCopier(true);
        if (selection) {
            selection.removeAllRanges();
      }
        setTimeout(()=>{
            setisCopier(false)
        },2000)
    }
    const updatedate = (date) =>{
        const updatedShareExternaleData = {
            ...shareExternaleData,
            expiration_date: date 
        };
        setshareExternaleData(updatedShareExternaleData);
    }
    useEffect(()=>{
        if(Array.isArray(shareExternaleData.emails_array)){
            let emails=""
            shareExternaleData.emails_array.length > 0 && shareExternaleData.emails_array.map((item,index)=>{
                emails += `${index != 0 ? `,${item}` : item } `
            })
            setEmails(emails)
        }
    },[])

    console.log("shareExternaleData ==> " , shareExternaleData)

    return(
        <Modal
            show={true}
            dialogClassName={"modal-40w modal-dialog modal-dialog-centered"}
        >
            <Modal.Body className=" flex-column " style={{display:"flex",gap:"0px"}}>
                <div className=" w-100  flex-row justify-content-between" style={{display:"flex",alignItems:"center",marginBottom:"20px"}}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.09 13.51L15.92 17.49M15.91 6.51L9.09 10.49M21.5 5C21.5 6.65685 20.1569 8 18.5 8C16.8431 8 15.5 6.65685 15.5 5C15.5 3.34315 16.8431 2 18.5 2C20.1569 2 21.5 3.34315 21.5 5ZM9.5 12C9.5 13.6569 8.15685 15 6.5 15C4.84315 15 3.5 13.6569 3.5 12C3.5 10.3431 4.84315 9 6.5 9C8.15685 9 9.5 10.3431 9.5 12ZM21.5 19C21.5 20.6569 20.1569 22 18.5 22C16.8431 22 15.5 20.6569 15.5 19C15.5 17.3431 16.8431 16 18.5 16C20.1569 16 21.5 17.3431 21.5 19Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <button className="btn p-0" onClick={()=>{setopenExternalShare(false)}}>
                            <svg
                                width="28"
                                height="28"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M14.1663 5.83325L5.83301 14.1666M5.83301 5.83325L14.1663 14.1666"
                                stroke="#667085"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            </svg>
                    </button>
                </div>
                <div className=" flex-row justify-content-between" style={{display:"flex",alignItems:"center"}}>
                    <div>
                        <h4 style={{
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "28px",
                                    color:"#101828",
                                    margin:"0px"
                                    
                                }}
                        >
                                    External sharing</h4>
                        <p style={{
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "20px",
                                    color:"#475467"
                                }}>Share a link with external partners</p>
                    </div>
                    <label class="switch" >
                        <input type="checkbox" checked={switchBtn} onChange={()=>{
                            if(switchBtn)
                                sendFunction(false)
                            else
                                GetMappingExternalInfo()     
                            setSswitchBtn(!switchBtn)                
                        }}/>
                        <span class={`slider ${switchBtn ? " toggleActive" : " toggleNoActive"}`}></span>
                    </label>
                </div>
                {
                    switchBtn && !loadingExternalShare
                    ?
                    <>
                        <div className=" flex-column" style={{gap:"16px",display:"flex"}}>
                            <div className="">
                                <label htmlFor="" style={{
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                color:"#101828"
                                            }}>Expiry date</label>
                                <input onChange={(e)=>{updatedate(e.target.value)}} type="date" className="form-control" value={ shareExternaleData.expiration_date.split("T")[0]} />
                            </div>
                            <div className="">
                                <label htmlFor="" style={{
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                color:"#101828"
                                            }}>URL</label>
                                <div className="flex-row justify-content-between position-relative" style={{display:"flex",gap:"12px",alignItems:"start"}}>
                                    <div style={{width:"100%"}}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={shareExternaleData.token}
                                            autoComplete="off"
                                            id="URLInput"
                                        />
                                        {moment(new Date()) > moment(new Date(shareExternaleData.expiration_date)) ?  <span style={{fontSize:"14px",color:"red"}}>
                                            Your expiration date has passed, so the link is no longer valid. Please update the expiration date.
                                        </span>:null}
                                        {console.log(" => "  ,moment(new Date()),new Date(new Date(shareExternaleData.expiration_date)))}

                                    </div>
     
                                    {
                                        isCopier ?
                                        <span style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color:"#5F75E8",
                                            width:"100px"
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                                                <path d="M14.6673 5L5.50065 14.1667L1.33398 10" stroke="#5F75E8" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            Copied
                                        </span>
                                        :
                                        <button
                                            onClick={ () => {copierUrl()}}
                                            style={{ width: "100px",padding: "5px 10px",color:"#5f76e8",background:" rgba(98, 117, 231, 0.08)" }}
                                            className="btn btn-info "
                                        >
                                            Copy
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="" style={{
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                color:"#101828"
                                            }}>Send invite</label>
                                <div className="flex-row justify-content-between" style={{display:"flex",gap:"12px"}}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={emails}
                                        autoComplete="off"
                                        onChange={(e)=>{setEmails(e.target.value);}}
                                        placeholder="email1@address.com,email2@address.com,email3@address.com"
                                    />
                                    {/* <button
                                        onClick={ () => {sendFunction()}}
                                        style={{padding: "5px 10px",color:"#5f76e8",background:" rgba(98, 117, 231, 0.08)",width:"100px" }}
                                        className="btn btn-info "
                                        disabled={Loading}
                                    >
                                         {Loading?<Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> :null}
                                        Send
                                        
                                    </button> */}
                                </div>
                                <div style={{width:"100%",height:"100px",overflowY:"scroll",margin:"10px 0px",padding:"0px 5px"}}>
                                    {
                                        Array.isArray(shareExternaleData.emails_array) &&
                                            shareExternaleData.emails_array.map((item,index)=>{
                                                return(
                                                    <span style={{
                                                        color:'101828',
                                                        font:"14px",
                                                        fontWeight:'400',
                                                        marginTop:"5px"
                                                    }}>{item}{shareExternaleData.emails_array.length > index+1 ? ',' : null}{' '}</span>
                                                )
                                            })
                                    }                                
                                </div>
                                
                            </div>
                             <div className="flex-row justify-content-end" style={{display:"flex",gap:"12px"}}>
                                    <button
                                        onClick={ () => {setopenExternalShare(false)}}
                                        style={{padding: "5px 20px",color:"black",background:"lightgrey",width:"100px" }}
                                        className="btn btn-info "
                                        type="primary"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={ () => {sendFunction()}}
                                        style={{padding: "5px 10px",color:"white",background:"#5F75E8",width:"100px" }}
                                        className="btn btn-info "
                                        type="secondary"
                                        disabled={Loading}
                                    >
                                        {Loading?<Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> :null}
                                        Save
                                    </button>
                                </div>
                        </div>
                    </>
                    :
                    null
                }
                {loadingExternalShare ?<div style={{textAlign:"center"}}><Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /></div> :null}
            </Modal.Body>
        </Modal>
    )
}