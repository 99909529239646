import axios from "axios";
const PATH_URL = process.env.REACT_APP_BASE_URL;

const NotificationsService = {
    get_notifications: async () => {
        const result = await axios
            .get(`${PATH_URL}/notifications/`,{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                console.log("res => " , res.data.data)
                return {
                    data: res.data?.data,
                    status: res.data.code,
                };
            })
            .catch((error) => {
                console.log("error", error);
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
    marke_notif_as_read: async (id) => {
        const result = await axios
            .put(`${PATH_URL}/notifications/make_notif_read`,{notification_id:id},{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.msg,
                    status: res.data.code,
                };
            })
            .catch((error) => {
                console.log("error", error);
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
};
export default NotificationsService;
